import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CodeElement from "../components/Editor";
import MathJax from "react-mathjax";
import { useTitle } from "../use-title";
import ChapterHOC from "../ChapterHOC";

const Chapter = () => {
  useTitle("Untitled Chapter");
return (
 <MathJax.Provider>
   <ChapterHOC>
{' '}<h2 id="writing-interactive-gui-for-the-flossmanual">Writing interactive
GUI for the flossmanual</h2>{' '}
{' '}<ul>{' '}
{' '}<li>must be delimited with
{' '}<code>&lt;CsoundWebElements&gt;&lt;/CsoundWebElements&gt;</code> tags
outside of <code>&lt;CsoundSynthesizer&gt;</code> (top or bottom)</li>{' '}
{' '}<li>currently no nesting is possible, self-closing elements as long as
they are wrapped between Gui tags</li>{' '}
{' '}</ul>{' '}
{' '}<h3 id="slider">&lt;Slider&gt;</h3>{' '}
{' '}<p>Has the following properties (in xml-slang &#x201c;attributes&#x201d;)</p>{' '}
{' '}<ul>{' '}
{' '}<li><code>id</code> type string: the name of the channel it should send
values to</li>{' '}
{' '}<li><code>name</code> type string: meaningful name that is displaued in
the GUI window</li>{' '}
{' '}<li><code>min</code> type number: smallest value possible in the
slider</li>{' '}
{' '}<li><code>max</code> type number: largest value possible in the
slider</li>{' '}
{' '}<li><code>defaultValue</code> type number: the starting value of the
slider</li>{' '}
{' '}</ul>{' '}
{' '}<div className="code-container csound"><CodeElement lang="csound" data={`&lt;CsoundSynthesizer&gt;
&lt;CsOptions&gt;
-odac
&lt;/CsOptions&gt;
&lt;CsInstruments&gt;

sr = 44100
ksmps = 64
nchnls = 2
0dbfs = 1

instr 1
 kc   chnget    &quot;freq&quot;
 a1   poscil     0.2, kc
 out(a1,a1)
endin

&lt;/CsInstruments&gt;
&lt;CsScore&gt;
i 1 0 360 0.2 200
&lt;/CsScore&gt;
&lt;/CsoundSynthesizer&gt;

&lt;CsoundWebElements&gt;
 &lt;Slider id=&quot;freq&quot; name=&quot;Cutoff frequency&quot; min=&#123;200&#125; max=&#123;2000&#125; defaultValue=&#123;440&#125; /&gt;
&lt;/CsoundWebElements&gt;`}></CodeElement></div>{' '}
</ChapterHOC>
</MathJax.Provider>
)};

export default Chapter;
